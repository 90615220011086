const firebaseConfig = {
  apiKey: 'AIzaSyCnkS19vIy3tHSdUTtrr2_3KxbujQnCmkw',
  authDomain: 'agion-field.firebaseapp.com',
  projectId: 'agion-field',
  storageBucket: 'agion-field.appspot.com',
  messagingSenderId: '328538752254',
  appId: '1:328538752254:web:ccccfc98c5d154b57011af',
  measurementId: 'G-XBKCG03KNV'
}

export default {
  development: {
	socket: 'https://api.clipei.agiontecnologia.com.br/',
    api: 'https://api.clipei.agiontecnologia.com.br/v1/',
    blob: 'https://api.clipei.agiontecnologia.com.br/v1/static/storage/',
    token: '002b84dfc0cb5aa7be955a28bd59f5c2ba8221d38d32b41ba834c6b138ded7f6',
    aws_access_key_id: 'AKIARGXRORSZM3MX6OWF',
    aws_secret_access_key: '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',
    aws_region: 'us-west-2',
    aws_bucket: 'clipei-producao/transcricao'  
},
  test: {
    socket: 'https://api.clipei.agiontecnologia.com.br/',
    api: 'https://api.clipei.agiontecnologia.com.br/v1/',
    blob: 'https://api.clipei.agiontecnologia.com.br/v1/static/storage/',
    token: '002b84dfc0cb5aa7be955a28bd59f5c2ba8221d38d32b41ba834c6b138ded7f6',
    aws_access_key_id: 'AKIARGXRORSZM3MX6OWF',
    aws_secret_access_key: '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',
    aws_region: 'us-west-2',
    aws_bucket: 'clipei-producao/transcricao'
  },
  production: {
    socket: 'https://api.clipei.agiontecnologia.com.br/',
    api: 'https://api.clipei.agiontecnologia.com.br/v1/',
    blob: 'https://api.clipei.agiontecnologia.com.br/v1/static/storage/',
    token: '002b84dfc0cb5aa7be955a28bd59f5c2ba8221d38d32b41ba834c6b138ded7f6',
    aws_access_key_id: 'AKIARGXRORSZM3MX6OWF',
    aws_secret_access_key: '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',
    aws_region: 'us-west-2',
    aws_bucket: 'clipei-producao/transcricao'
  }
}

export { firebaseConfig }
