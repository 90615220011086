import React, { Suspense, lazy } from 'react'
import { Routes, Route, createBrowserRouter, RouterProvider } from 'react-router-dom'
import AuthLayout from './AuthLayout'

import Loading from '../components/Loading'

const SignIn = lazy(() => import('../pages/Auth/SignIn'))
const Recover = lazy(() => import('../pages/Auth/Recover'))
const PasswordChange = lazy(() => import('../pages/Auth/PasswordChange'))

const NotFound = lazy(() => import('../pages/404'))

const Config = lazy(() => import('../pages/Config'))
const Transcription = lazy(() => import('../pages/Transcription'))

// HOME
const Home = lazy(() => import('../pages/Home/index'))

// CLIPEI
const ClipeiProgramIndex = lazy(() => import('../pages/Clipei/programs'))
const ClipeiVehicleIndex = lazy(() => import('../pages/Clipei/vehicles'))
const ClipeiScheduleIndex = lazy(() => import('../pages/Clipei/agendas'))

// VEHICLES
// const VehicleIndex = lazy(() => import('../pages/Vehicle/Index'))
// const VehicleForm = lazy(() => import('../pages/Vehicle/Form'))
// const VehicleView = lazy(() => import('../pages/Vehicle/View'))

// PROGRAMS
const ProgramIndex = lazy(() => import('../pages/Program/Index'))
const ProgramForm = lazy(() => import('../pages/Program/Form'))
const ProgramView = lazy(() => import('../pages/Program/View'))

// SCHEDULE
// const ScheduleIndex = lazy(() => import('../pages/Schedule/Index'))
// const ScheduleForm = lazy(() => import('../pages/Schedule/Form'))
// const ScheduleView = lazy(() => import('../pages/Schedule/View'))

// BLOCKS
const BlockIndex = lazy(() => import('../pages/Block/Index'))
const BlockForm = lazy(() => import('../pages/Block/Form'))
const BlockView = lazy(() => import('../pages/Block/View'))

// CLIPPING
const ClippingIndex = lazy(() => import('../pages/Clipping/Index'))
const ClippingView = lazy(() => import('../pages/Clipping/View'))

// USERS
const UserIndex = lazy(() => import('../pages/User/Index'))
const UserForm = lazy(() => import('../pages/User/Form'))
const UserForce = lazy(() => import('../pages/User/Form/forcePass'))
const UserView = lazy(() => import('../pages/User/View'))

const MyRoutes = () => (
  <Suspense fallback={<Loading />}>
    <Routes>
      <Route element={<AuthLayout login />}>
        <Route index element={<SignIn />} />
        <Route path='*' component={NotFound} />
      </Route>

      <Route element={<AuthLayout />}>
        <Route path='/recuperar' exact element={<Recover />} />
      </Route>

      <Route element={<AuthLayout isPrivate />}>
        <Route path='/configuracoes' exact element={<Config />} />
        <Route path='/transcricao' exact element={<Transcription />} />

        <Route path='/trocar-senha' exact element={<PasswordChange />} />

        <Route path='/inicio' exact element={<Home />} />

        <Route path='/usuarios' exact element={<UserIndex />} />
        <Route path='/usuario/:username' exact element={<UserView />} />
        <Route path='/usuario/:username/editar' exact element={<UserForm />} />
        <Route path='/usuario/:username/senha' exact element={<UserForce />} />
        <Route path='/usuario/novo' exact element={<UserForm />} />

        <Route path='/clippings' exact element={<ClippingIndex />} />
        <Route path='/clipping/:id' exact element={<ClippingView />} />

        <Route path='/programas' exact element={<ProgramIndex />} />
        <Route path='/programa/:id' exact element={<ProgramView />} />
        <Route path='/programa/:id/editar' exact element={<ProgramForm />} />
        <Route path='/programa/novo' exact element={<ProgramForm />} />

        <Route path='/blocos' exact element={<BlockIndex />} />
        <Route path='/bloco/:id' exact element={<BlockView />} />
        <Route path='/bloco/:id/editar' exact element={<BlockForm />} />
        <Route path='/bloco/novo' exact element={<BlockForm />} />

        {/* CLIPEI INFO */}
        <Route path='/agendas-clipei' exact element={<ClipeiScheduleIndex />} />
        <Route path='/veiculos-clipei' exact element={<ClipeiVehicleIndex />} />
        <Route path='/programas-clipei' exact element={<ClipeiProgramIndex />} />
      </Route>

    </Routes>
  </Suspense>
)

const router = createBrowserRouter([{ path: '*', Component: MyRoutes }])
const AppRoutes = () => (<RouterProvider router={router} />)

export default AppRoutes
